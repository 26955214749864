import { type NitroFetchRequest } from 'nitropack'
import {useUserStore} from "~/stores/user";
import {FetchContext, FetchResponse} from "ofetch/dist/node";
import {useNotificationStore} from "~/stores/util/notifications";
import {useErrorStore} from "~/stores/util/errors";

export function $api<T = unknown, R extends NitroFetchRequest = NitroFetchRequest>(
    request: Parameters<typeof $fetch<T, R>>[0],
    options?: Partial<Parameters<typeof $fetch<T, R>>[1]>,
) {
    const store = useUserStore()
    const config = useRuntimeConfig()

    return $fetch<T, R>(request, {
        ...options,
        baseURL: config.public.baseURL,
        headers: {
            Accept: 'application/json',
            Authorization: store.isAuthenticated ? `Bearer ${store.token}` : '',
            ...options?.headers
        },
        onResponse(context: FetchContext & { response: FetchResponse<ResponseType> }): Promise<void> | void {
            const data = context.response._data

            if (data.message && data.message !== 'Unauthenticated.') {
                useNotificationStore().addNotification({
                    message: context.response._data.message,
                    type: context.response.status < 300 ? 'success' : 'error',
                    duration: 5000,
                })
            }

            if ([200, 201].includes(context.response.status)) {
                useErrorStore().clearFormErrors()
            }

            if (context.response.status == 401) {
                // navigateTo('/login')
                useUserStore().logout()
            }

            if (data.errors && context.response.status == 422) {
                useErrorStore().clearFormErrors()
                useErrorStore().setFormErrors(data.errors)
            }
        },

    })
}